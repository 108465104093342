import * as type from './types';
import * as user_type from '../user/types';
import property from 'lodash/property';
import React from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import {_sendLog} from "../../utils/utility";

const POLICY_TOKEN_KEY = 'policyAuthToken';
const PORTFOLIO_TOKEN_KEY = 'portfolioToken';

const authCpHeader = (state) => {
    const cpToken = _getCpToken(state);
    if (cpToken) {
        return { 'Authorization': 'Bearer ' + cpToken };
    } else {
        return {};
    }
}

export function _showError(errors) {
    console.log(errors);
    return (dispatch, getState) => {
        dispatch({
            type: type.SHOW_ERROR,
            errors: errors
        });
    }
}


export function _clearError() {
    return (dispatch, getState) => {
        dispatch({
            type: type.CLEAR_ERROR
        });
    }
}


export function _showModal(data) {
    return (dispatch, getState) => {
        dispatch({
            type: type.SHOW_MODAL,
            data: data
        });
    }
}

export function _hideModal() {
    return (dispatch, getState) => {
        dispatch({
            type: type.HIDE_MODAL,
        });
    }
}


export function _showSpinner() {
    return (dispatch, getState) => {
        dispatch({
            type: type.SHOW_SPINNER
        });
    }
}

export function _hideSpinner() {
    return (dispatch, getState) => {
        dispatch({
            type: type.HIDE_SPINNER
        });
    }
}

export function _setLang(lang) {
    return (dispatch, getState) => {
        dispatch({
            type: type.SET_LANG,
            lang: lang?.toLowerCase()
        });
    }
}

export const _setOtpToken = (otpToken) => {
    return (dispatch) => {
        dispatch({
            type: type.SET_OTP_TOKEN,
            data: otpToken
        });
    }
}

export const _setCpToken = (cpToken, iv) => {
    return (dispatch) => {
        dispatch({
            type: type.SET_CP_TOKEN,
            token: cpToken
        });
    }
}

export const _verifyOtp = (otp) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                params: {
                    otp: otp
                }
            }

            axios.get(process.env.REACT_APP_VERIFY_OTP, config)
                .then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    _sendLog(['Customer Portal React - System Service - _verifyOtp', JSON.stringify(error)],
                        'error');
                    reject(error);
            })
        })
    }
}


export const _verifyOtpAndGetUserDetail = (otp, email) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                params: {
                    otp: otp,
                    email: email.toLowerCase(),
                    country: _getCountry(getState())
                },
                timeout: 30000
            }

            axios.get(process.env.REACT_APP_VERIFY_OTP_AND_GET_USER_DETAIL, config)
                .then((response) => {
                    if (response.data.success) {
                        let otpToken = {
                            iv: '',
                            token: ''
                        }
                        dispatch({
                            type: type.SET_OTP_TOKEN,
                            data: otpToken
                        });
                        dispatch({
                            type: user_type.SET_USER,
                            data: response.data.userDetails
                        })
                    }
                    resolve(response.data);
                }).catch((error) => {
                    _sendLog(['Customer Portal React - System Service - _verifyOtpAndGetUserDetail', JSON.stringify(error)],
                        'error');
                    reject(error);
                }
            )
        })
    }
}

export function _setNoLogin(noLogin) {
    return (dispatch, getState) => {
        dispatch({
            type: type.SET_NO_LOGIN,
            noLogin: noLogin
        });
    }
}

export function _setCountry(country) {
    return (dispatch, getState) => {
        dispatch({
            type: type.SET_COUNTRY,
            country: country
        });
    }
}

export function _setSecurityToken(token) {
    return (dispatch, getState) => {
        dispatch({
            type: type.SET_SECURITY_TOKEN,
            token: token
        });
    }
}

export function _clearPolicyToken() {
    return (dispatch, getState) => {
        // window.sessionStorage.removeItem(POLICY_TOKEN_KEY);
        dispatch({
            type: type.RESET_POLICY_TOKEN
        });
    }
}

export function _clearPortfolioToken() {
    return (dispatch, getState) => {
        // window.sessionStorage.removeItem(POLICY_TOKEN_KEY);
        dispatch({
            type: type.RESET_POLICY_TOKEN
        });
    }
}


export function _clearToken() {
    return (dispatch, getState) => {
        console.log("clear tokens");
        // window.sessionStorage.removeItem(POLICY_TOKEN_KEY);
        // window.sessionStorage.removeItem(PORTFOLIO_TOKEN_KEY);
        dispatch({
            type: type.RESET_STATE
        });
    }
}

export function _setPolicyToken(token) {
    return (dispatch) => {
        console.log("_setPolicyToken: "+ token);
        // window.sessionStorage.removeItem(POLICY_TOKEN_KEY);
        // window.sessionStorage.setItem(POLICY_TOKEN_KEY, token);
        dispatch({
            type: type.SET_POLICY_TOKEN,
            token: token
        });
    }

}

export function _setDocumentToken(token) {
    return (dispatch, getState) => {
        dispatch({
            type: type.SET_DOCUMENT_TOKEN,
            token: token
        });
    }
}

export const _isAuthenticated = () => {
    let token = _getSecurityToken();
    if (token) {
        const decoded = jwt_decode(token);
        const expiryDate = new Date( decoded.exp * 1000);
        return expiryDate > new Date();
    }
    return false;
}

export function _setShowOtpModal(value) {
    return (dispatch) => {
        dispatch({
            type: type.SET_SHOW_OTP_MODAL,
            showOtpModal: value
        });
    }
}

export const _generatePortfolioToken = (userId, clientId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            let config = {
                params: {
                    userId: userId,
                    clientId: clientId
                }
            }

            axios.get(process.env.REACT_APP_GENERATE_PORTFOLIO_ACCESS_TOKEN, config).then(
                resp => {
                    if (resp.data.success) {
                        dispatch({
                            type: type.SET_PORTFOLIO_TOKEN,
                            data: resp.data.customerPortalToken
                        });
                        // window.sessionStorage.removeItem(PORTFOLIO_TOKEN_KEY);
                        // window.sessionStorage.setItem(PORTFOLIO_TOKEN_KEY, resp.data.customerPortalToken);
                    }
                    resolve();
                }
            ).catch(error => {
                _sendLog(['Customer Portal React - System Service - _generatePortfolioToken', JSON.stringify(error)],
                    'error');
                reject(error);
            })
        });
    }
}

export const _setPortfolioToken = (token) => {
    return (dispatch) => {
        console.log("_setPortfolioToken: "+ token);
        // window.sessionStorage.removeItem(PORTFOLIO_TOKEN_KEY);
        // window.sessionStorage.setItem(PORTFOLIO_TOKEN_KEY, token);
        dispatch({
            type: type.SET_PORTFOLIO_TOKEN,
            data: token
        });
    }
}

export const _setFindMyPolicyBusinessState = (value) => {
    return (dispatch) => {
        dispatch({
            type: type.SET_FIND_MY_POLICY_BUSINESS_STATE,
            findMyPolicyBusinessState: value
        })
    }
}

export const _clearState = () => {
    return (dispatch) => {
        dispatch({
            type: type.RESET_STATE
        });
    }
}

export const _setShowPageBroken = (value) => {
    return (dispatch) => {
        dispatch({
            type: type.SET_PAGE_BROKEN,
            showPageBroken: value
        });
    }
}

export const _generatePolicyTokenFromPortfolioScreen = (policyNumber, country) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                params: {
                    policyNumber: policyNumber,
                    country: country
                },
                timeout: 30000
            }

            axios.get(process.env.REACT_APP_GENERATE_POLICY_TOKEN_PORTFOLIO_SCREEN, config)
                .then(response => {
                    // window.sessionStorage.removeItem(POLICY_TOKEN_KEY);
                    // window.sessionStorage.setItem(POLICY_TOKEN_KEY, response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    _sendLog(['Customer Portal React - System Service - _generatePolicyTokenFromPortfolioScreen', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _generatePolicyToken = (policyNumber, country) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                params: {
                    policyNumber: policyNumber,
                    country: country
                },
                timeout: 30000
            }

            axios.get(process.env.REACT_APP_GENERATE_POLICY_TOKEN, config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    _sendLog(['Customer Portal React - System Service - _generatePolicyToken', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _setSkipFlashDoneToTrue = () => {
    return (dispatch) => {
        dispatch({
            type: type.SET_SKIP_FLASH_DONE_TO_TRUE
        })
    }
}

export const _setAdminLogin = (value) => {
    return (dispatch) => {
        dispatch({
            type: type.SET_ADMIN_LOGIN,
            adminLogin: value
        })
    }
}


export const _generateSingpassUrl = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let cn = _getCountry(getState())
            axios.get(process.env.REACT_APP_GENERATE_SINGPASS_URL)
              .then(resp => {
                  resolve(resp);
              }).catch(error => {
                reject(error);
            });
        })
    }
}

export const _getSingpassScript = () => {
    return (dispatch, getState) => {
        let config = {
            headers: authCpHeader(getState())
        }

        return new Promise((resolve, reject) => {
            axios.get(process.env.REACT_APP_SINGPASS_SCRIPT, config )
                .then(resp => {
                    resolve(resp.data);
                }).catch(error => {
                _sendLog(['Customer Portal React - Policy Service - _getSingpassScript', JSON.stringify(error)], 'error');
                reject(error);
            });
        })
    }
}

export const _singpassInit = () => {
    return (dispatch, getState) => {
        let config = {
            headers: authCpHeader(getState())
        }
        return new Promise((resolve, reject) => {
            axios.get(process.env.REACT_APP_SINGPASS_INIT, config )
                .then(resp => {
                    resolve(resp.data);
                }).catch(error => {
                _sendLog(['Customer Portal React - Policy Service - _singpassInit', JSON.stringify(error)], 'error');
                reject(error);
            });
        })
    }
}

export const _singpassCallback = (callbackCode, callbackState) => {
    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                params: {
                    code: callbackCode,
                    state: callbackState
                }
            }

            axios.get(process.env.REACT_APP_SINGPASS_CALLBACK, config)
                .then((response) => {
                    if (response.data.success) {
                        let otpToken = {
                            iv: '',
                            token: ''
                        }
                        dispatch({
                            type: type.SET_OTP_TOKEN,
                            data: otpToken
                        });
                        dispatch({
                            type: user_type.SET_USER,
                            data: response.data.userDetails
                        })
                    }
                    resolve(response.data);
                }).catch(error => {
                _sendLog(['Customer Portal React - Policy Service - _singpassCallback', JSON.stringify(error)], 'error');
                reject(error);
            });
        })
    }
}

export const _checkSystemAvailable = (country) => {
    return async (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const server = (process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');
            const httpOptions = { headers: {'Content-Type': 'application/json'} };

            axios.post(server + process.env.REACT_APP_CHECK_SYSTEM_STATUS_URL, {countryCode: country}, httpOptions)
                .then(resp => {
                    const systemAvailable = resp.data?.toString() === "true";
                    dispatch({
                        type: type.SET_SYSTEM_AVAILABLE,
                        systemAvailable: systemAvailable
                    });
                    resolve(systemAvailable);
                })
                .catch(error => {
                    _sendLog(['PAN-React - System Service - _checkSystemAvailable: ', error]);
                    reject(error);
                });
        });
    }
}

export const _getScheduledMaintenance = (country) => {
    return async (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const server = (process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');
            const httpOptions = { headers: {'Content-Type': 'application/json'} };
            axios.post(server + process.env.REACT_APP_CHECK_SCHEDULED_MAINTENANCE_URL, {countryCode: country}, httpOptions)
                .then(resp => {
                    if(resp) {
                       resolve(resp);
                    }
                })
                .catch(error => {
                    _sendLog(['PAN-React - System Service - _checkSystemAvailable: ', error]);
                    reject(error);
                });
        });
    }
}

export const _getBanners = (country) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                params: {
                    country: country,
                    product: "CP"
                },
                headers: authCpHeader(getState()),
                timeout: 600000,
            }

            axios.get(process.env.REACT_APP_GET_BANNERS_URL, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Customer Portal React - Policy Service - _getBanners', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _setGotoPage = (gotoPage, gotoPn) => {
    return (dispatch) => {
        dispatch({
            type: type.SET_GOTO_PAGE,
            gotoPage: gotoPage,
            gotoPn: gotoPn,
        });
    }
}

export const _getErrors = property('sys.errors');

export const _getShowError = property('sys.showError');

export const _getShowModal = property('sys.showModal');

export const _getModal = property('sys.modal');

export const _getShowSpinner = property('sys.spinner');

export const _getViewOnly = property('sys.viewOnly');

export const _getTransactionType = property('sys.transactionType');

export const _getLang = property('sys.lang');

export const _getToken = property('sys.otpToken');

export const _getCpToken = property('sys.cpToken');

export const _isNoLogin = property('sys.noLogin');

export const _getCountry = property('sys.country');

export const _isHongKong = property('sys.country') === 'HKG';

export const _isSingapore = property('sys.country') === 'SGP';

export const _isMalaysia = property('sys.country') === 'MYS';
export const _getSecurityToken = property('sys.securityToken');

export const _getPolicyToken = property('sys.policyToken');

// export const _getPolicyToken = () => {
    // let policyToken = window.sessionStorage.getItem(POLICY_TOKEN_KEY)
    // if (policyToken && !_getPolicyTokenFromState()) {
    //     _setPolicyToken(policyToken);
    // }
    // return policyToken;
// }

export const _getDocumentToken = property('sys.documentToken');

export const _getShowOtpModal = property('sys.showOtpModal');

// export const _getPortfolioToken = () => {
    // let portfolioToken =  window.sessionStorage.getItem(PORTFOLIO_TOKEN_KEY);
    // if (portfolioToken && !_getPortfolioTokenFromState()) {
    //     _setPortfolioToken(portfolioToken);
    // }
    // return portfolioToken;
// }

export const _getPortfolioToken = property('sys.portfolioToken');

export const _getFindMyPolicyBusinessState = property('sys.findMyPolicyBusinessState');

export const _getShowPageBroken = property('sys.showPageBroken');

export const _getSkipFlashDone = property('sys.skipFlashDone');

export const _getAdminLogin = property('sys.adminLogin');

export const _isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const _getSystemAvailable = property('sys.systemAvailable');

export const _getGotoPage = property('sys.gotoPage');

export const _getGotoPn = property('sys.gotoPn');
