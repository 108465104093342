import React, {useState} from 'react';
import {_getLang, _setCountry, _setLang, _showError} from "../store/system/service";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {_adhocFindPaymentDetails, _renewLoadPolicy, _storePolicyPayment} from "../store/policy-payment/service";
import QBESpinner from "../assets/QBE-spinner.gif";
import {_sendLog} from "../utils/utility";
import useConfig from "../components/qnect-ui/qnect-config";

const PaymentPage = (props) => {
    console.debug('processing adhoc payment');
    const [loading, setLoading] = useState( false );
    const params = new URLSearchParams(props.history.location.search);
    const token = params.get('token');
    const iv = params.get('vector');
    const country = params.get('country')
    let countryLanguage = "en"; // default
    if (country && (country.toUpperCase() === 'HKG' || country.toUpperCase() === 'HGI')){
        countryLanguage = 'zh';
    }
    const config = useConfig(countryLanguage);

    _sendLog(' payment-page.js - token = ' + token + ', iv = ' + iv, 'info');

    if (!loading && token && iv) {
        setLoading(true);
        console.debug('token = '+token+', iv=' + iv);
        _sendLog(' payment-page.js - calling adhocLoadPolicy()...', 'info');
        props.adhocLoadPolicy(token, iv).then((resp) => {
            _sendLog(' payment-page.js - adhocLoadPolicy(): resp =\n\n' + JSON.stringify(resp), 'info');
            if (resp?.status === 'OK') {
                props.storePolicyPayment(resp?.policyPayment);
                if (!(resp?.policyPayment.country === 'HKG' || resp?.policyPayment.country === 'HGI')) {
                    props.setLang('en')
                } else {
                    props.setLang('zh');
                }
                if (resp?.policyPayment.country === 'SGP') {
                    props.setCountry('SGP');
                }
                props.history.push("/payment-detail");
            } else {
                if(resp.errorMessage){
                    const errorMsg = config(`errorMessage.${resp.errorMessage}`);
                    if (!errorMsg.startsWith("errorMessage.")){
                        props.showError([errorMsg, resp.errorPolicyNumber]);
                    } else {
                        // if no corresponding language-specific string value of the error message
                        props.showError([resp.errorMessage]);
                    }
                } else {
                    const errorMsg = config(`errorMessage.${resp?.msg}`);
                    if (!errorMsg.startsWith("errorMessage.")){
                        props.showError([errorMsg]);
                    } else {
                        // if no corresponding language-specific string value of the error message
                        props.showError([resp?.msg]);
                    }
                }
                _sendLog(' payment-page.js - adhocLoadPolicy(): errorMessage = ' + resp?.msg);
            }
        }, (error) => {
            props.showError([error.response?.data]);
            _sendLog(' payment-page.js - adhocLoadPolicy(): exception:\n\n' + JSON.stringify(error));
        });
    };

    return (
        <>
            <div id="splash-screen">
                <img src={QBESpinner} alt="QBE spinner" />
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    adhocLoadPolicy: _adhocFindPaymentDetails,
    showError: _showError,
    storePolicyPayment: _storePolicyPayment,
    setCountry: _setCountry,
    setLang: _setLang
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentPage));
