import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import QbeLogo from '../../assets/QBE-logo-on-light.png';
import VisaImg from '../../assets/Visa_logo.svg';
import MastercardImg from '../../assets/Mastercard_logo.svg';
import PayNowImg from '../../assets/logo_paynow.png';
import CreditCardIcon from '../../assets/icon_creditcard.svg';
import PayNowIcon from '../../assets/icon_paynow.svg';
import styled from 'styled-components';
import QnectButton from "../../components/qnect-ui/qnect-button";
import QnectTextField from "../../components/qnect-ui/qnect-text-field";
import BalloonTip from "../ui/balloon-tip/balloon-tip";
import useConfig from "../qnect-ui/qnect-config";
import {
    _getCountry,
    _getLang,
    _getPolicyToken,
    _getPortfolioToken,
    _hideSpinner,
    _setLang,
    _showError,
    _showSpinner
} from "../../store/system/service";
import {
    _adhocAcceptPayment,
    _adhocFailPayment,
    _adhocInitiatePayment,
    _getPolicyPayment,
    _storePolicyPayment,
} from "../../store/policy-payment/service";
import {
    _formatPaymentAmount,
    _getPolPaymentCurrency,
    _isNotDecimalChar,
    _sendLog,
    _validateForm
} from "../../utils/utility";
import Header from "../custom/header";
import {_resetPolicy} from "../../store/policy/service";
import * as TagManager from "react-gtm-module";
import QnectRadioBox from "../qnect-ui/qnect-radio-box";
import '../../assets/styles/payment.css';
import QrCode from "../qr-code-payment/qr-code";
import {rgba} from "polished";
import {OPTIN} from "../auto-renewal/auto-renewal-constants";

const InputGroupStyle = styled.div`
    width: 100%;
    margin: 0 auto;
    padding-top: 16px;
    padding-bottom: 22px;
`;

const Overlay = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 99;
    background: ${({theme}) => rgba(theme.color.backdrop, 0.8)};
    ${({show}) => {
    if(show) {
        return "width: unset; height: unset"
    } else {
        return "width: 500px; height: 0px"
    }
}}
`;

const AdhocPayment = (props) => {
    const formId = 'paymentOption';
    const ccFormId = 'adhocPaymentCCForm';
    const cfg = useConfig(props.lang);

    const [paymentSignature, setPaymentSignature] = useState(null);
    const formRef = useRef(null);

    const [lang, setLang] = useState( props.lang);
    const [policyNumber] = useState (props.policyPayment.policyNumber);
    const [totalAmount] = useState(props.policyPayment.amount);
    const [paymentOption, setPaymentOption] = useState(props.policyPayment.country === 'SGP' ? props.policyPayment.paymentOption : 'C');
    const [cardHolderName, setCardHolderName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCvv] = useState('');

    const paymentGatewayIframeRef = useRef(null);
    const [showPaymentGatewayIframeModal, setShowPaymentGatewayIframeModal] = useState(false);
    const [otpMonitorActive, setOtpMonitorActive] = useState(false);
    const [otpMonitorTimer, setOtpMonitorTimer] = useState(0);
    const [otpMonitorIntervalId, setOtpMonitorIntervalId] = useState(null);
    const [paymentIframeContentLength, setPaymentIframeContentLength] = useState(0);
    const [eligibleForAutoRenewal] = useState (props.policyPayment.eligibleForAutoRenewal);
    const [autoRenewalStatus] = useState (props.policyPayment.autoRenewalStatus);

    /**
     * If auto-renewal is supported then, we check for auto-renewal status if PayNow QR Code is an option.
     * If auto-renewal status is ON/OPT-IN, then PayNow QR Code should be hidden. Otherwise, status = OFF/OPT-OUT, then it is shown.
     * <br>
     * For ALL OTHER PRODUCT that does not support auto-renewal it should be true by default.
     * @returns {boolean|*}
     */
    const showPayNowQrCodeByAutoRenewalStatus = () => {
        if (eligibleForAutoRenewal) {
            return autoRenewalStatus !== OPTIN;
        }

        return true;
    }

    const originalConsoleError = console.error;
    console.error = function () {
        const errorArguments = Array.from(arguments);
        const errorMessage = errorArguments.map(arg => (typeof arg === 'object' ? JSON.stringify(arg) : arg)).join(" ");
        _sendLog("adhoc-payment.js: " + errorMessage, "error")
        originalConsoleError.apply(console, arguments);
    }

    const getPaymentOptions = () => {
        let options = [];
        let obj = {};

        if (props.policyPayment.country === 'SGP') {
            if (props.policyPayment.paymentMethod) { //PayNow
                if (!props.policyPayment.catalogCodeAllowCreditCardPayment) { // non-SME
                    obj = {};
                    obj['optionLabel'] = 'Pay by Credit card';
                    obj['optionLabel_zh'] = '信用卡付款';
                    obj['optionValue'] = 'C';
                    obj['optionIcon'] = CreditCardIcon;
                    options.push(obj);
                }
                if (!props.policyPayment.disablePayNow && showPayNowQrCodeByAutoRenewalStatus()) {
                    obj = {};
                    obj['optionLabel'] = 'Pay by PayNow';
                    obj['optionLabel_zh'] = '以PayNow支付';
                    obj['optionValue'] = 'P';
                    obj['optionIcon'] = PayNowIcon;
                    options.push(obj);
                }
            } else { //PayFast
                if (props.policyPayment.catalogCodeAllowCreditCardPayment) {
                    obj = {};
                    obj['optionLabel'] = 'Pay by Credit card';
                    obj['optionLabel_zh'] = '信用卡付款';
                    obj['optionValue'] = 'C';
                    obj['optionIcon'] = CreditCardIcon;
                    options.push(obj);

                }
                if (!props.policyPayment.disablePayNow && showPayNowQrCodeByAutoRenewalStatus()) {
                    obj = {};
                    obj['optionLabel'] = 'Pay by PayNow';
                    obj['optionLabel_zh'] = '以PayNow支付';
                    obj['optionValue'] = 'P';
                    obj['optionIcon'] = PayNowIcon;
                    options.push(obj);
                }
            }
        } else {
            obj['optionLabel'] = 'Pay by Credit card';
            obj['optionLabel_zh'] = '信用卡付款';
            obj['optionValue'] = 'C';
            obj['optionIcon'] = CreditCardIcon;
            options.push(obj);
        }

        return options;
    }

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'vpageview',
                pageStep: 'Payment Process – Payment - ' + props.policyPayment.productType,
                vPath: '/payment-process',
                vProduct: props.policyPayment.productType,
                vPolicyNo: props.policyPayment.policyNumber,
                vPaymentCurrency: _getPolPaymentCurrency(props.policyPayment.country),
                vPaymentAmount: totalAmount,
                vCountry: props.policyPayment.country,
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: 1
                        }
                    }
                }
            }
        });

    }, []);

    useEffect(() => {
        window.paymentSuccess = paymentSuccess.bind(this);
        window.paymentError = paymentError.bind(this);

        //cleanup
        return () => {
            delete window.paymentSuccess;
            delete window.paymentError;
        }
    }, [props.policyPayment]);

    useEffect(() => {
        if (otpMonitorTimer > 0) {
            monitorPaymentGatewayResponse();
        }
    }, [otpMonitorTimer]);

    useEffect(() => {
        if (otpMonitorActive && !otpMonitorIntervalId) {
            setOtpMonitorIntervalId(setInterval(() => {
                setOtpMonitorTimer((otpMonitorTimer) => otpMonitorTimer + 1);
            }, 1000));
        }
    }, [otpMonitorActive]);

    const switchLanguage = () => {
        let newLang = lang === 'en' ? 'zh' : 'en';
        setLang(newLang);
        props.setLang(newLang);
    }

    //if catalogueCode is invalid and payNow is disabled, display msg
    const isPaymentOptionsAvailable = () => {
        if (props.policyPayment.country === 'SGP') {
            if (props.policyPayment.paymentMethod) { //PayNow
                if (props.policyPayment.catalogueCodeValidForPayNowOrPayFast && props.policyPayment.disablePayNow) {
                    return false;
                }
            } else { //PayFast
                if (!props.policyPayment.catalogueCodeValidForPayNowOrPayFast && props.policyPayment.disablePayNow) {
                    return false;
                }
            }
        }
        return true;
    }

    const onChangeCardNumberFormatHandler = (event) => {
        let cardNoRegex = new RegExp("^\\d{4}-\\d{4}-\\d{4}-\\d{4}$", "g");
        let newCardNo = '';
        let preventDefault = false;
        if (!cardNoRegex.test(event.target.value)) {
            let cardNo = event.target.value.replace(/-/g, '');
            if (cardNo.length > 16) {
                cardNo = cardNo.substring(0, 16);
            }

            if (!(/\D/g.test(cardNo))) {
                do {
                    if (cardNo.length > 4) {
                        newCardNo = newCardNo + cardNo.substring(0, 4) + '-';
                        cardNo = cardNo.substring(4, cardNo.length);
                    } else {
                        newCardNo = newCardNo + cardNo;
                        cardNo = '';
                    }
                } while (cardNo.length > 0);
            } else {
                preventDefault = true;
            }
        } else {
            newCardNo = event.target.value;
        }

        if (!preventDefault) {
            setCardNumber(newCardNo);
        } else {
            event.preventDefault();
        }
    };

    const onChangeExpiryDateHandler = (event) => {
        let newExpiryDate = event.target.value.trim();
        if (newExpiryDate) {
            if (newExpiryDate.length === 4 && newExpiryDate.indexOf("-") === -1) {
                newExpiryDate = newExpiryDate.substring(0, 2) + '-' + newExpiryDate.substring(2);
            }
        }

        setExpiryDate(newExpiryDate);
    };

    const processPayment = () => {
        if(!_validateForm(ccFormId)){
            return;
        }
        props.policyPayment.cardHolder = cardHolderName;
        props.policyPayment.expiryDate = expiryDate;
        props.policyPayment.cvv = cvv;
        props.storePolicyPayment(props.policyPayment);
        props.showSpinner();

        // remove injected input by SingPass which causes Cybersource payment error
        if (document.querySelector('form[target="payment_iframe"] > input[name="nds-pmd"]')) {
            document.querySelector('form[target="payment_iframe"]').removeChild(document.querySelector('input[name="nds-pmd"]'));
        }

        _sendLog('AdhocPayment - processPayment(): calling initiatePayment()...', 'info');
        props.initiatePayment(props.policyPayment).then(resp => {
            _sendLog('AdhocPayment - processPayment() - initiatePayment() checkpoint ', 'info');
            let cardType = '001';
            let ccExpiryDate = expiryDate.substring(0, 2) + "-20" + expiryDate.substring(3);
            let creditCardNumber = '';

            if (cardNumber) {
                creditCardNumber = cardNumber.replace(/\s/g, '').replace(/-/g, '');
                if (new RegExp('^5[1-5]*').test(cardNumber)) {
                    cardType = '002';
                }
            }

            resp.cardType = cardType;
            resp.cardNumber = creditCardNumber;
            resp.expiryDate = ccExpiryDate;

            setPaymentSignature(resp);
            formRef.current.submit();
        }, (error) => {
            props.hideSpinner();
            props.showError([error.response?.data]);
            _sendLog(' adhoc-payment.js - processPayment() - initiatePayment(): exception:\n\n' + JSON.stringify(error.response?.data));
            _sendLog('AdhocPayment - processPayment() - initiatePayment(): exception:\n\n' + JSON.stringify(error));
        });
    }

    const paymentSuccess = (referenceNumberToken, referenceNumber, auth_code, receiptNumber,
                            email, paymentToken, cardExpiryDate, cardNumber, forename, surname, transactionType) => {
        _sendLog('AdhocPayment - paymentSuccess(): referenceNumber = ' + referenceNumber + ', auth_code = ' + auth_code + ', receiptNumber = ' + receiptNumber, 'info');

        if(paymentGatewayIframeRef?.current){
            setShowPaymentGatewayIframeModal(false);
            clearInterval(otpMonitorIntervalId);
            setOtpMonitorIntervalId(null);
            setOtpMonitorActive(false);
            props.showSpinner();
        }

        props.policyPayment.payment = {
            paymentReferenceNumber: referenceNumber,
            paymentReferenceNumberToken: referenceNumberToken,
            auth_code: auth_code,
            receiptNumber: receiptNumber,
            email: email,
            paymentToken: paymentToken,
            cardExpiryDate: cardExpiryDate,
            cardNumber: cardNumber,
            forename: forename,
            surname: surname,
            transactionType: transactionType
        };
        Object.keys(props.policyPayment.payment).forEach(function (key) {
            if(typeof props.policyPayment.payment[key] === 'undefined'){
                delete props.policyPayment.payment[key];
            }
        });

        _sendLog('AdhocPayment - paymentSuccess(): calling acceptPayment()...', 'info');
        props.acceptPayment(props.policyPayment).then(resp => {
            _sendLog('AdhocPayment - paymentSuccess() - acceptPayment(): resp =\n\n' + JSON.stringify(resp), 'info');
            props.hideSpinner();
            props.storePolicyPayment(resp);
            props.resetPolicy();
            props.history.push('/payment-finish');
        }, (error) => {
            props.hideSpinner();
            props.showError(['Error in issuing policy']);
            _sendLog('AdhocPayment - paymentSuccess(): acceptPayment(): exception:\n\n' + JSON.stringify(error));
        });
    }

    const paymentError = (message, invalidFields, reasonCode, referenceNumber) => {
        _sendLog('paymentError(): message = ' + message + ', invalidFields = ' + invalidFields + ', reasonCode = ' + reasonCode, 'info');
        let errorMessage = message + '&lt;br&gt;' + 'Transaction number: ' + referenceNumber;

        if(paymentGatewayIframeRef?.current){
            setShowPaymentGatewayIframeModal(false);
            clearInterval(otpMonitorIntervalId);
            setOtpMonitorIntervalId(null);
            setOtpMonitorActive(false);
            setPaymentIframeContentLength(0);
        }

        props.hideSpinner();
        props.showError([errorMessage]);
        _sendLog('AdhocPayment - paymentError(): errorMessage = ' + errorMessage, 'info');

        props.failPayment(props.policyPayment).then(resp => {
            console.log(resp);
        }, errorMessage => {
            console.error(errorMessage);
        })
    }

    const canGoBack = () => {
        if (props.portfolioToken || props.policyToken) {
            return true;
        }
        return false;
    }

    const monitorPaymentGatewayResponse = () => {
        // For MYS, OTP popup dialog appears when iframe contentWindow.length == 2
        // MYS non-OTP payment will still have some network transactions that at sometime set iframe contentWindow.length == 1
        // HKG and SGP payment transactions always have iframe contentWindow.length == 0
        let paymentIframeDocumentContentCount = document.querySelector('iframe[name=payment_iframe]').contentWindow.length;

        if (paymentIframeDocumentContentCount > 0 && paymentIframeContentLength !== paymentIframeDocumentContentCount) {
            setPaymentIframeContentLength(paymentIframeDocumentContentCount);

            // monitor actual iframe content length for future auditing/debugging in prod
            _sendLog('AdhocPayment - monitorPaymentGatewayResponse for policy ' + policyNumber
                + ", payment_iframe content length = " + paymentIframeDocumentContentCount, 'info');
        }

        if (paymentGatewayIframeRef?.current && otpMonitorIntervalId) {
            if (!showPaymentGatewayIframeModal && paymentIframeDocumentContentCount > 1) {
                _sendLog('AdhocPayment - OTP dialog loaded for policy ' + policyNumber, 'info');
                // stop monitoring
                clearInterval(otpMonitorIntervalId);
                setOtpMonitorActive(false);

                // show otp dialog
                setShowPaymentGatewayIframeModal(true);
                props.hideSpinner();
            }
        }
    }

    const paymentGatewayIframeOnLoad = (e) => {
        _sendLog('AdhocPayment - iframe onLoad for policy ' + policyNumber, 'info');

        if (!otpMonitorActive) {
            // iframe contentWindow.length == 0 at this point
            // need a timer to monitor what's being loaded on payment_iframe afterwards
            setOtpMonitorActive(true);
        }
    }

    return (
        <div className="pageContainer adhoc-payment-page">
            <div className="container">

                {canGoBack() ?
                    <Header/>
                    :
                    <div className="topRow">
                        <div className="left">
                            <a href="https://www.qbe.com/hk/en/about-qbe">
                                <img src={QbeLogo} width="122" height="35" alt="QBE"/>
                            </a>
                        </div>
                        <div className="right">
                            <div className="languageSelect" onClick={() => switchLanguage()}
                                 style={{ visibility: (props.policyPayment.country === 'HKG' ||
                                     props.policyPayment.country === 'HGI'  ? 'visible' : 'hidden')}}>
                                {(lang === 'en') ?
                                    cfg("labels.lang_zh")
                                    :
                                    cfg("labels.lang_en")
                                }
                            </div>
                        </div>
                    </div>
                }

                <div className="main-content-container-boxed">
                    <div className="main-content-container-box">
                        <div className="main-content-title">{ cfg('labels.payment') }</div>
                        <div className="main-content-box">
                            { (isPaymentOptionsAvailable() && props.policyPayment.country === 'SGP') && <div className="payment-option-section">
                                <div className="payment-option-title">{ cfg('labels.paymentOptionTitle') }</div>
                                <QnectRadioBox
                                    className="payment-option-radio"
                                    field="paymentOption"
                                    formId={formId}
                                    value={paymentOption}
                                    options={ getPaymentOptions() }
                                    onChange={(value) => setPaymentOption(value)}
                                />
                            </div>
                            }

                            { !isPaymentOptionsAvailable()  && <div className="payment-option-title">
                                { cfg('labels.noAvailablePaymentOption') }
                            </div> }

                            {
                                paymentOption === 'C' &&
                                <div id="pay-by-credit-card-section">
                                    <div className="pricing-row no-topMargin">
                                        <div className="pricing-block">
                                            <div className="highlight-text alignCenter">{ cfg('labels.totalAmount') }</div>
                                            <div className="main-highlight-text alignCenter">{ _formatPaymentAmount(
                                                props.policyPayment.country, totalAmount) }</div>
                                            <div className="credit-cards">
                                                <button className="c-card">
                                                    <img src={VisaImg} width="80" height="46" alt="Visa"/>
                                                </button>
                                                <button className="c-card">
                                                    <img src={MastercardImg} width="80" height="46" alt="MasterCard"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-container">
                                        <div className="form-field-container">
                                            <InputGroupStyle>
                                                <QnectTextField field="cardholderName" formId={ccFormId}
                                                                value={cardHolderName}
                                                                onChange={(event) => {
                                                                    setCardHolderName(event.target.value.toUpperCase());
                                                                }}
                                                />
                                            </InputGroupStyle>
                                            <div className="form-caption">
                                                <span>
                                                    { cfg(props.policyPayment.country === 'SGP' ? 'labels.cardHolderNameHelperTextSGP' : 'labels.cardHolderNameHelperText') }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-field-container">
                                            <InputGroupStyle>
                                                <QnectTextField field="cardNumber" formId={ccFormId}
                                                                value={cardNumber}
                                                                onChange={(event) => {
                                                                    onChangeCardNumberFormatHandler(event)
                                                                }}
                                                                onKeyPress={(event) => {
                                                                    if (_isNotDecimalChar(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                maxLength={19}
                                                />
                                            </InputGroupStyle>
                                        </div>
                                        <div className="two-column">
                                            <div className="form-field-container">
                                                <InputGroupStyle>
                                                    <QnectTextField field="expiryDate" formId={ccFormId}
                                                                    value={expiryDate}
                                                                    onChange={(event) => onChangeExpiryDateHandler(event)}
                                                                    onKeyPress={(event) => {
                                                                        if (_isNotDecimalChar(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    maxLength={5}
                                                    />
                                                </InputGroupStyle>
                                            </div>
                                            <div className={"form-field-container icon-info-container with-ccv-icon " + (lang === 'en' ? 'eng':'chi')}>
                                                <InputGroupStyle>
                                                    <QnectTextField field="cvv" formId={ccFormId}
                                                                    value={cvv}
                                                                    onChange={(event) => setCvv(event.target.value)}
                                                                    onKeyPress={(event) => {
                                                                        if (_isNotDecimalChar(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                    />
                                                </InputGroupStyle>
                                                <BalloonTip>
                                                    { cfg('labels.cvvTip')}
                                                </BalloonTip>
                                            </div>
                                        </div>
                                        { (eligibleForAutoRenewal && autoRenewalStatus === OPTIN) &&
                                            <div className="form-field-container">
                                                <div className="form-caption cc-note">
                                                    <span>{cfg('labels.autoRenewal.creditCardNote')}</span>
                                                </div>
                                            </div>
                                        }
                                        <div className="form-field-container button-container button-size1">
                                            <InputGroupStyle>
                                                <QnectButton field="confirmPayNow" onClick={processPayment} disabled={!(cardHolderName && cardNumber && expiryDate && cvv)}>
                                                </QnectButton>
                                            </InputGroupStyle>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                paymentOption === 'P' &&
                                <div id="pay-by-paynow-section">
                                    <div className="pricing-row no-topMargin">
                                        <div className="pricing-block">
                                            <div className="highlight-text alignCenter">{ cfg('labels.totalAmount') }</div>
                                            <div className="main-highlight-text alignCenter">{ _formatPaymentAmount(
                                                props.policyPayment.country, totalAmount) }</div>
                                            <div className="credit-cards">
                                                <a href="#">
                                                    <button className="c-card">
                                                        <img src={PayNowImg} width="80" height="46" alt="PayNow"/>
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-container">
                                        <div className="form-field-container">
                                            <div className="payment-caption">
                                                <span>{ cfg('labels.scanQrCode')}</span>
                                            </div>
                                            <div className="qr-code">
                                                <QrCode />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div id="pay_confirm_section">
                <form ref={formRef} action={paymentSignature?.paymentUrl} method="post"
                      target="payment_iframe">
                    <input type="hidden" id="access_key" name="access_key"
                           value={paymentSignature?.access_key || ''}/>
                    <input type="hidden" id="profile_id" name="profile_id"
                           value={paymentSignature?.profile_id || ''}/>
                    <input type="hidden" id="transaction_uuid"
                           name="transaction_uuid" value={paymentSignature?.transaction_uuid || ''}/>
                    <input type="hidden" id="signed_field_names" name="signed_field_names"
                           value={paymentSignature?.signed_field_names || ''}/>
                    <input type="hidden" id="unsigned_field_names" name="unsigned_field_names"
                           value={paymentSignature?.unsigned_field_names || ''}/>
                    <input type="hidden" id="signed_date_time" name="signed_date_time"
                           value={paymentSignature?.signed_date_time || ''}/>
                    <input type="hidden" id="locale" name="locale" value={paymentSignature?.locale || ''}/>
                    <input type="hidden" id="transaction_type" name="transaction_type"
                           value={paymentSignature?.transaction_type || ''}/>
                    <input type="hidden" id="reference_number" name="reference_number"
                           value={paymentSignature?.reference_number || ''}/>
                    <input type="hidden" id="amount" name="amount"
                           value={paymentSignature?.amount || ''}/>
                    <input type="hidden" id="currency" name="currency"
                           value={paymentSignature?.currency || ''}/>
                    <input type="hidden" id="payment_method" name="payment_method"
                           value={paymentSignature?.payment_method || ''}/>
                    <input type="hidden" id="bill_to_forename" name="bill_to_forename"
                           value={paymentSignature?.bill_to_forename || ''}/>
                    <input type="hidden" id="bill_to_surname" name="bill_to_surname"
                           value={paymentSignature?.bill_to_surname || ''}/>
                    <input type="hidden" id="bill_to_email" name="bill_to_email"
                           value={paymentSignature?.bill_to_email || ''}/>
                    <input type="hidden" id="bill_to_phone" name="bill_to_phone"
                           value={paymentSignature?.bill_to_phone || ''}/>
                    <input type="hidden" id="bill_to_address_line1" name="bill_to_address_line1"
                           value={paymentSignature?.bill_to_address_line1 || ''}/>
                    <input type="hidden" id="bill_to_address_city" name="bill_to_address_city"
                           value={paymentSignature?.bill_to_address_city || ''}/>
                    <input type="hidden" id="bill_to_address_state"
                           name="bill_to_address_state"
                           value={paymentSignature?.bill_to_address_state || ''}/>
                    <input type="hidden" id="bill_to_address_country" name="bill_to_address_country"
                           value={paymentSignature?.bill_to_address_country || ''}/>
                    <input type="hidden" id="bill_to_address_postal_code" name="bill_to_address_postal_code"
                           value={paymentSignature?.bill_to_address_postal_code || ''}/>
                    <input type="hidden" id="override_custom_receipt_page" name="override_custom_receipt_page"
                           value={paymentSignature?.override_custom_receipt_page || ''}/>
                    <input type="hidden" id="signature" name="signature"
                           value={paymentSignature?.paymentSignatureKey || ''}/>
                    <input type="hidden" id="card_type" name="card_type"
                           value={paymentSignature?.cardType || ''}/>
                    <input type="hidden" id="card_number" name="card_number"
                           value={paymentSignature?.cardNumber || ''}/>
                    <input type="hidden" id="card_expiry_date" name="card_expiry_date"
                           value={paymentSignature?.expiryDate || ''}/>
                    <input type="hidden" id="card_cvn" name="card_cvn" value={cvv || ''}/>
                    { (paymentSignature?.payment_token) &&
                        <input type="hidden" id="payment_token" name="payment_token"
                               value={paymentSignature?.payment_token || ''}/>
                    }
                </form>

                <Overlay show={showPaymentGatewayIframeModal}>
                    <iframe className={showPaymentGatewayIframeModal ? "PaymentGatewayIframeVisible" : "PaymentGatewayIframeHidden"} name="payment_iframe" src="" title="Payment" onLoad={paymentGatewayIframeOnLoad} ref={paymentGatewayIframeRef}></iframe>
                </Overlay>

            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        policyPayment: _getPolicyPayment(state),
        lang: _getLang(state),
        portfolioToken: _getPortfolioToken(state),
        policyToken: _getPolicyToken(state),
        country: _getCountry(state)
    }
};

const mapDispatchToProps = {
    setLang: _setLang,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showError: _showError,
    resetPolicy: _resetPolicy,
    storePolicyPayment: _storePolicyPayment,
    initiatePayment: _adhocInitiatePayment,
    failPayment: _adhocFailPayment,
    acceptPayment: _adhocAcceptPayment,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdhocPayment));

