import React, {useEffect, useState} from "react";
import QnectButton from "../../qnect-ui/qnect-button";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "../dialog-title";
import Dialog from '../dialog';
import useConfig from "../../../components/qnect-ui/qnect-config";
import FieldWrapper from "../../custom/style-text-field-wrapper";
import QnectTextField from "../../qnect-ui/qnect-text-field";
import {makeStyles} from "@material-ui/core/styles";
import styled, {css} from "styled-components";
import {StyledModalDiv} from "../../../components/login-style";
import zxcvbn from "zxcvbn";
import isEmpty from "validator/es/lib/isEmpty";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as HideIcon} from "../../../assets/registration/icon_hide.svg";
import {ReactComponent as ShowIcon} from "../../../assets/registration/icon_show.svg";

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: "100%",
        background: "rgb(246,248,250)",
        minHeight: "calc(100vh - 64px)",
        '& .MuiFormControl-root.TextField' : {
            marginBottom: '10px',
            marginTop: '10px',
        },
        [theme.breakpoints.down("sm")]: {
            minHeight: "calc(100vh + 28px)",
        },
        [theme.breakpoints.up("lg")]: {}
    },
    topBg: {
        width: "100%",
        backgroundColor: "#FFFFFF",
        [theme.breakpoints.down("sm")]: {},
        [theme.breakpoints.up("lg")]: {
            height: "432px"
        }
    },
    topRow: {
        margin: "0 auto",
        width: "100%",
        maxWidth: "1440px",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            height: "44px",
            padding: "0 12px",
            alignItems: "center",
            borderBottom: "1px solid #E1E4E8"
        },
        [theme.breakpoints.up("lg")]: {
            alignItems: "flex-end",
            height: "75px"
        }
    },
    logo: {
        [theme.breakpoints.down("sm")]: {
            width: "70px"
        },
        [theme.breakpoints.up("lg")]: {
            width: "122px",
            height: "35px"
        }
    },
    main: {
        backgroundColor: "#FFFFFF",
        boxShadow: "0 4px 4px 0 rgba(0,0,0,0.04)",
        [theme.breakpoints.down("sm")]: {
            margin: "20px auto",
            padding: "20px 16px",
            height: "454px",
            width: "100%",
            boxShadow: "unset",
            marginTop: "0",
            paddingTop: "0",
            paddingLeft: "3px",
            paddingRight: "3px"
        },
        [theme.breakpoints.up("md")]: {
            boxShadow: "unset",
        },
        [theme.breakpoints.up("lg")]: {
            margin: "0 auto",
            padding: "10px 40px 20px 40px",
            height: "fit-content",
            width: "600px"
        },
        fontFamily: "Inter-Regular",
        fontSize: "16px",
    },
    title: {
        fontFamily: "Stag-Medium",
        fontSize: "24px",
        fontWeight: "500",
        letterSpacing: "0",
        lineHeight: "28px",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            marginBottom: "0px",
            color: "#003DA5",
            fontSize: "21px"
        },
        [theme.breakpoints.up("lg")]: {
            marginBottom: "57px",
            color: "#003DA5"
        }
    },
    message: {
        color: "#0A1F44",
        fontFamily: "Inter",
        letterSpacing: "0",
        textAlign: "center",
        lineHeight: "24px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
            lineHeight: "normal",
            "&:last-child": {
                marginTop: "4px"
            }
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: "18px",
            "&:last-child": {
                marginTop: "37px"
            }
        }
    },
    image: {
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            "& svg": {
                width: "260px"
            }
        },

    },
    outerTitle: {
        [theme.breakpoints.down("sm")]: {
            margin: "20px auto",
            padding: "20px 16px",
            height: "454px",
            width: "92%",
        },
        [theme.breakpoints.up("lg")]: {
            margin: "0 auto",
            width: "600px",
            height: '88px',
        },
        position: "relative",
        top: "0",
        textAlign: "center",
        fontFamily: "Stag-Medium",
        fontSize: "24px",
        backgroundColor: '#f1f2f4',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: '2px solid #009AE4'
    },
    firstLine: {
        marginBottom: "5px",
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "bold",
        lineHeight: '24px',
    },
    passwordFields: {
        paddingBottom: "16px",
    }
}));

const RequirementsDiv = styled.div`
    margin-bottom: 20px;

    ul {
        padding-left: 30px;
        @media only screen and (max-width: 490px){
            margin-top: 0;
        }
    }
    
    li {
        padding-top: 8px;
    }
`;

const StyledList = styled.li`
    padding-left: 7px;
    margin-left: -7px; 
    
    ${({passed, cond2Status}) => {
        if (passed) {
            return css`
                        list-style-type:  '✓'; 
                        color: #009AE4;
                    `
        } else {
            if (cond2Status) {
                return css`
                        list-style-type:  disc;   
                        opacity: 30%;
                        margin-left: 2px;
                    `
            } else {
                return css`
                        list-style-type:  '✖';   
                        color: #D54040;
                    `
            }
            
        }
    }}
`;

const PasswordInputsDiv = styled.div`
    padding: 0 45px;
    @media only screen and (max-width: 490px){
        padding: 0 15px;
    }
`;

const SavePasswordDiv = styled.div`
    text-align: center;
    button {
        border-radius: 27px;
        height: 52px;
        padding: 0 27px;
        margin-top: 20px;
    }
`

const StyledModalDiv2 = styled(StyledModalDiv)`
    padding-top: 32.2px;
    width: 600px;
    height: 257px;
    .title {
        margin-bottom: 40px;
    }
    
    div.content {
        margin-bottom: 40px;
    }
    
    div.buttonDiv {
        margin: 0 auto;
        
        button {
            width: 180px;
        }
    }

`;

export const ExpiredPasswordDialog = ({open, onClose, onOk, props, passwordMinLength}) => {
    const classes = useStyles();
    const cfg = useConfig();

    const [cond1, setCond1] = useState(false);
    const [cond2, setCond2] = useState(false);
    const [cond3, setCond3] = useState(false);
    const [cond4, setCond4] = useState(false);
    const [cond5, setCond5] = useState(false);
    const [cond6, setCond6] = useState(false);
    const [cond7, setCond7] = useState(false); // if password contains username
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);

    const [password, setPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [currPasswordErrorMessage, setCurrPasswordErrorMessage] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('');
    const [openDialog, setOpenDialog] = useState(open);
    const [isShowCurrentPassword, setIsShowCurrentPassword] = useState(false);
    const [isShowNewPassword, setIsShowNewPassword] = useState(false);
    const [isShowVerifyPassword, setIsShowVerifyPassword] = useState(false);

    const handleClickShowCurrentPassword = () => {
        setIsShowCurrentPassword(!isShowCurrentPassword);
    };

    const handleClickShowNewPassword = () => {
        setIsShowNewPassword(!isShowNewPassword);
    };

    const handleClickShowVerifyPassword = () => {
        setIsShowVerifyPassword(!isShowVerifyPassword);
    };

    useEffect(() => {
        setOpenDialog(open);
    },[open]);


    useEffect(() => {
        if (password.length >= passwordMinLength) {
            setCond1(true);
        } else {
            setCond1(false);
        }

        let usernameWithoutDomain = props.user.email.split("@") ? props.user.email.split("@")[0] : props.user.email;
        if(password.includes(usernameWithoutDomain) || isCommonPassword(password)) {
            setCond7(false);
        } else {
            setCond7(true);
        }

        let cond2Checker = 0;

        if (password.match(/[a-z]/g)){
            cond2Checker++;
            setCond3(true);
        } else {
            setCond3(false);
        }

        if (password.match(/[A-Z]/g)){
            cond2Checker++;
            setCond4(true);
        } else {
            setCond4(false);
        }

        if (password.match(/\d/g)){
            cond2Checker++;
            setCond5(true);
        } else {
            setCond5(false);
        }

        if (password.match(/((?=.*[!@#$%&*()_+=|<>?{}\[\]~-]).+$)/g)){
            cond2Checker++;
            setCond6(true);
        } else {
            setCond6(false);
        }

        if (cond2Checker > 2) {
            setCond2(true);
        } else {
            setCond2(false);
        }

        // confirmPassword validation
        let confirmPasswordValidCount = 0;

        if (confirmPassword.match(/[a-z]/g)) {
            confirmPasswordValidCount++;
        }

        if (confirmPassword.match(/[A-Z]/g)){
            confirmPasswordValidCount++;
        }

        if (confirmPassword.match(/\d/g)){
            confirmPasswordValidCount++;
        }

        if (confirmPassword.match(/((?=.*[!@#$%&*()_+=|<>?{}\[\]~-]).+$)/g)){
            confirmPasswordValidCount++;
        }

        if (confirmPassword.length >= 8 && confirmPasswordValidCount >= 2) {
            setIsConfirmPasswordValid(true);
        } else {
            setIsConfirmPasswordValid(false);
        }
    }, [password, confirmPassword]);

    const isCommonPassword = (password) => {
        var isCommonPass = true;
        var result=zxcvbn(password);

        if(result.score>=2){
            isCommonPass = false;
        }
        return isCommonPass;
    }


    const handleResetPasswordSubmit = () => {
        if (password != confirmPassword) {
            setPasswordErrorMessage(cfg('registration.errorMessages.passwordNotMatch'));
            setConfirmPasswordErrorMessage(cfg('registration.errorMessages.passwordNotMatch'));
            return;
        }

        if(isEmpty(currentPassword)) {
            setCurrPasswordErrorMessage('currentPassword.errorMessages.required');
            return;
        } else {
            setCurrPasswordErrorMessage('');
        }

        props.showSpinner();
        props.changePassword(password, currentPassword).then((resp) => {
            if (resp === 'SUCCESS') {
                setOpenDialog(false);
                props.showModal({
                    flat: true,
                    content: <StyledModalDiv2>
                        <div className="title"
                             style={props.lang === 'zh' ? {fontWeight: "bold"} : {}}>
                            {cfg('forgotPassword.resetPwMessage')}
                        </div>
                        <div className="buttonDiv">
                            <QnectButton
                                onClick={() => {
                                    props.hideModal();
                                }}
                                field="close"
                            ></QnectButton>
                        </div>
                    </StyledModalDiv2>,
                    disableBackDropClick: true
                })
            } else if (resp === 'FAILED') {
                setPasswordErrorMessage(cfg('resetPassword.errorMessages.notLast8'));
                setConfirmPasswordErrorMessage(cfg('resetPassword.errorMessages.notLast8'));
            } else if (resp === "INVALID") {
                setCurrPasswordErrorMessage(cfg('currentPassword.errorMessages.invalid'));
            }
            props.hideSpinner();
        }, (error) => {
            if (error.response.status === 500 || error.response.status === 400 || error.response.status === 404) {
                setPasswordErrorMessage(cfg('errorMessage.serverError'));
                setConfirmPasswordErrorMessage(cfg("errorMessage.serverError"));
            } else {
                setPasswordErrorMessage(error.response.statusText);
                setConfirmPasswordErrorMessage(error.response.statusText);
            }
            props.hideSpinner();
        });
    }

    return (
            <div className={classes.modal_box_container}>
                <Dialog open={openDialog} className={classes.modal_box_container_wide} style={{width: "100%", left: "50%", transform: 'translateX(-50%)'}}>
                    <DialogTitle className={classes.title}
                        closable={false}
                        title={cfg("expiredPassword.title")}
                    />
                    <DialogContent>
                        <div style={{marginTop: '10px'}}>
                            <div className={classes.main}>
                                <RequirementsDiv>
                                    <div className={classes.firstLine}>{cfg("forgotPassword.last8PassUsed")}</div>
                                    <ul>
                                        <StyledList passed={cond1}>{cfg("forgotPassword.minLength") + passwordMinLength + cfg("forgotPassword.minLength2")}</StyledList>
                                        <StyledList passed={cond7}>{cfg("forgotPassword.notCommon")}</StyledList>
                                        <StyledList passed={cond2}>{cfg("forgotPassword.haveAtleast3CharTypes")}</StyledList>
                                        <ul>
                                            <StyledList passed={cond3} cond2Status={cond2}>{cfg("forgotPassword.aLowerCase")}</StyledList>
                                            <StyledList passed={cond4} cond2Status={cond2}>{cfg("forgotPassword.anUpperCase")}</StyledList>
                                            <StyledList passed={cond5} cond2Status={cond2}>{cfg("forgotPassword.aNumeric")}</StyledList>
                                            <StyledList passed={cond6} cond2Status={cond2}>{cfg("forgotPassword.aSpecial")}</StyledList>
                                        </ul>
                                    </ul>
                                </RequirementsDiv>
                                <PasswordInputsDiv>
                                    <FieldWrapper className={classes.passwordFields}>
                                        <QnectTextField
                                            field="resetPasswordCurrent"
                                            label="Current Password"
                                            type={isShowCurrentPassword ? 'text' : 'password'}
                                            errorMessage={currPasswordErrorMessage}
                                            onChange={(evt) => {
                                                setCurrentPassword(evt.target.value);
                                                setCurrPasswordErrorMessage('');
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowCurrentPassword}
                                                    >
                                                      {isShowCurrentPassword ? <HideIcon/> : <ShowIcon/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FieldWrapper>
                                    <FieldWrapper className={classes.passwordFields}>
                                        <QnectTextField
                                            field="resetPasswordNewPW"
                                            label="New Password"
                                            type={isShowNewPassword ? 'text' : 'password'}
                                            errorMessage={passwordErrorMessage}
                                            onChange={(evt) => {
                                                setPassword(evt.target.value);
                                                setPasswordErrorMessage('');
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowNewPassword}
                                                    >
                                                      {isShowNewPassword ? <HideIcon/> : <ShowIcon/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FieldWrapper>
                                    <FieldWrapper className={classes.passwordFields}>
                                        <QnectTextField
                                            field="resetPasswordVerifyNewPW"
                                            label="Verify Password"
                                            type={isShowVerifyPassword ? 'text' : 'password'}
                                            errorMessage={confirmPasswordErrorMessage}
                                            onChange={(evt) => {
                                                setConfirmPassword(evt.target.value);
                                                setConfirmPasswordErrorMessage('');
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowVerifyPassword}
                                                    >
                                                      {isShowVerifyPassword ? <HideIcon/> : <ShowIcon/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FieldWrapper>
                                </PasswordInputsDiv>
                                <SavePasswordDiv>
                                    <FieldWrapper>
                                        <QnectButton
                                            field="resetPassword"
                                            onClick={handleResetPasswordSubmit}
                                            disabled={ !cond1 || !cond2 || !isConfirmPasswordValid ? true : false }
                                        />
                                    </FieldWrapper>
                                </SavePasswordDiv>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
    );
}

export default ExpiredPasswordDialog;


