import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './assets/fonts/Stag-Medium.otf';
import './index.css';
import {v4 as uuid} from 'uuid';
import axios from 'axios';
import {_sendLog} from './utils/utility'
import TagManager from 'react-gtm-module'

import createAppStore from './store/app-store';
import App from "./App";
import {SET_PAGE_BROKEN} from "./store/system/types";

const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Wraps App in a fresh Redux store
function ReactReduxApp() {
    let gtmID = 'GTM-KHKTLMB'; //default is the SGP GA container id.
    const params = (new URL(document.location)).searchParams;
    const country = params.get('country');
    console.log('ga init country='+country)

    console.log('ga init gtmID='+gtmID)
    const tagManagerArgs = {
        gtmId: gtmID
    }
    TagManager.initialize(tagManagerArgs);
    console.log('ga init start')

    const [store] = useState(createAppStore);
    generateUuid();
    interceptError(store);

    return (
        <Provider store={store}>
            <App/>
        </Provider>
    );
}

function generateUuid() {
    console.log("env = " + process.env.REACT_APP_ENV);
    if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== 'dev') {
        const sessionId = uuid();
        console.log('Generated UUID: ' + sessionId);
        _sendLog('Customer-Portal-React - generateUuid - Generated UUID: ' + sessionId, 'info')
        axios.interceptors.request.use((request = {}) => {
            if (request?.params?.hasOwnProperty('handlerEnabled') && !request?.params?.handlerEnabled) {
                delete request.params.handlerEnabled;
                return request;
            }
            request.headers['Session-Name'] = sessionId;
            return request;
        });
        axios.interceptors.response.use(null, (error) => {
            return Promise.reject(error);
        });
    }
}

function interceptError(store) {
    axios.interceptors.request.use((request = {}) => {
        // console.log('Request interceptor: ', request);
        return request;
    });
    axios.interceptors.response.use((response) => {
        console.log('Response interceptor: ', response);
        return response;
    }, (error) => {
        if ((error.response?.status === 500
            || error.response?.status === 502
            || error.response?.status === 503
            || error.response?.status === 504
            || error.response?.status === 408 || error.response?.status === 404) && error.config.shouldIntercept !== 'false')
        {
            store.dispatch({
                type: SET_PAGE_BROKEN,
                showPageBroken: true
            });
        }
        return Promise.reject(error);
    });
}

ReactDOM.render(
    (
        <ReactReduxApp/>
    ),
    document.getElementById('root')
);
