import {property} from "lodash";
import * as type from "./types";
import moment from "moment";
import axios from "axios";
import {_getCountry, _getCpToken, _getPortfolioToken, _setPortfolioToken} from "../system/service";
import {_sendLog} from "../../utils/utility";

const authCpHeader = (state) => {
    const cpToken = _getCpToken(state);
    if (cpToken) {
        return { 'Authorization': 'Bearer ' + cpToken };
    } else {
        return {};
    }
}

export const _storeUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: type.SET_USER,
      data: user
    })
  };
}

export const _getUserDetailById = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                timeout: 60000
            }

            axios.get(process.env.REACT_APP_GET_USER_DETAIL_BY_ID, config)
                .then((response) => {
                    dispatch({
                        type: type.SET_USER,
                        data: response.data.userDetails
                    })
                    resolve(response.data.userDetails);
                })
                .catch((error) => {
                    _sendLog(['Customer Portal React - User Service - _getUserDetails', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _updateUserSkipFlash = (skipFlash) => {
    return (dispatch, getState) => {
        let user = _getUser(getState());
        user.skipFlash = skipFlash;
        dispatch({
          type: type.SET_USER,
          data: user
        })

        return new Promise((resolve, reject) => {
          let config = {
            headers: authCpHeader(getState()),
            params: {
              skipFlash: skipFlash,
            },
            timeout: 60000
          }

          axios.get(process.env.REACT_APP_UPDATE_USER_SKIP_FLASH, config).then((response) => {
              resolve();
          })
          .catch((error) => {
              _sendLog(['Customer Portal React - User Service - _updateUserSkipFlash', JSON.stringify(error)],
                  'error');
              reject(error);
          })
        })
    }
}

export const _getUserDetailsFromToken = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState())
            }

            axios.get(process.env.REACT_APP_GET_USER_DETAIL_BY_TOKEN, config)
                .then((response) => {
                    dispatch({
                        type: type.SET_USER,
                        data: response.data
                    })
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Customer Portal React - User Service - _getUserDetailsFromToken', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _sendRegisteredEmailToSMS = (phoneNumber, countryCode) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config;
            config = {
                params: {
                    country: _getCountry(getState()),
                    countryCode: countryCode,
                    phoneNumber: phoneNumber
                },
                headers: authCpHeader(getState()),
                timeout: 60000
            };

            axios.get(process.env.REACT_APP_SEND_REGISTERED_EMAIL_TO_SMS, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Customer Portal React - User Service - _sendRegisteredEmailToSMS', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _sendRegisteredMobileNumberToEmail = (email) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let country = _getCountry(getState());
            let config = {
                params: {
                    email: email,
                    country: country
                },
                headers: authCpHeader(getState()),
                timeout: 60000
            }

            axios.get(process.env.REACT_APP_SEND_REGISTERED_MOBILE_TO_EMAIL, config)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    _sendLog(['Customer Portal React - User Service - _sendRegisteredMobileNumberToEmail', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}


export const _addEmailAddressToOTPLockedCache = (email) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                params: {
                    email: email
                },
                headers: authCpHeader(getState()),
                timeout: 30000
            }

            axios.get(process.env.REACT_APP_ADD_EMAIL_ADDRESS_TO_OTP_LOCKED_CACHE, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Customer Portal React - User Service - _addEmailAddressToOTPLockedCache', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _checkEmailIfRegistered = (email, country) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const config = {
                params: {
                    email: email,
                    country: country
                },
                headers: authCpHeader(getState()),
                timeout: 60000
            }
            axios.get(process.env.REACT_APP_CHECK_EMAIL_IF_REGISTERED, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Customer Portal React - User Service - _checkEmailIfRegistered', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _verifyUserCredentials = (email, password) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const requestBody = {
                email: email.toLowerCase(),
                password: password,
                country: _getCountry(getState())
            };
            let config = {
                headers: authCpHeader(getState()),
                timeout: 60000
            }
            axios.post(process.env.REACT_APP_VERIFY_USER_CREDENTIALS, requestBody, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Customer Portal React - User Service - _verifyUserCredentials', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _verifyAdminUserCredentials = (adminEmail, password, email) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const requestBody = {
                adminEmail: adminEmail.toLowerCase(),
                password: password,
                country: _getCountry(getState()),
                email: email.toLowerCase()
            };
            let config = {
                headers: authCpHeader(getState()),
                timeout: 60000
            }
            axios.post(process.env.REACT_APP_VERIFY_ADMIN_USER_CREDENTIALS, requestBody, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Customer Portal React - User Service - _verifyAdminUserCredentials', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}


export const _registerEmailAndMobile = (email, countryCode, phoneNumber, password, isMobileModified = 'N') => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let user = _getUser(getState());
            let country = _getCountry(getState());

            let registrationBody = {
                country: country,
                policyNumber: user.policyNumber,
                email: email.toLowerCase(),
                countryCode: countryCode,
                phoneNumber: phoneNumber,
                isHGI: user.isHGI,
                password: password,
                isMobileModified: isMobileModified,
                hkId: user.idNumber
            }
            let config = {
                headers: authCpHeader(getState()),
                timeout: 60000
            }

            axios.post(process.env.REACT_APP_REGISTER_EMAIL_AND_MOBILE, registrationBody, config)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                _sendLog(['Customer Portal React - User Service - _registerEmailAndMobile', JSON.stringify(error)], 'error');
                reject(error);
            })
        })
    }
}

export const _resetUser = (policy) => {
  return (dispatch) => {
    dispatch({
      type: type.RESET_USER
    })
  };
}

export const _resendVerificationEmail = (email, country) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                params: {
                    email: email,
                    country: country
                },
                headers: authCpHeader(getState()),
                timeout: 60000
            }

            axios.get(process.env.REACT_APP_RESEND_VERIFICATION_EMAIL, config)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    _sendLog(['Customer Portal React - User Service - _resendVerificationEmail', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}


export const _verifyEmail = (token, iv) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                params: {
                    token: token,
                    iv: iv
                },
                headers: authCpHeader(getState()),
                timeout: 30000
            }

            axios.get(process.env.REACT_APP_VERIFY_EMAIL, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Customer Portal React - User Service - _verifyEmail', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _retrievePolicyList = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                timeout: 60000
            }

            axios.get(process.env.REACT_APP_RETRIEVE_POLICY_LIST, config)
                .then((response) => {
                    dispatch({
                        type: type.POLICY_LIST_STORE,
                        data: response.data.policyPortfolio
                    })

                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Customer Portal React - User Service - _retrievePolicyList', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _resetPolicyList = () => {
  return (dispatch) => {
    dispatch({
      type: type.POLICY_LIST_RESET
    })
  };
}


export const _checkIfPolicyNumberIsRegistered = (policyNumber, hkId, name) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                params: {
                    policyNumber: policyNumber,
                    hkId: hkId,
                    name: name,
                    country: _getCountry(getState())
                },
                headers: authCpHeader(getState())
            }

            axios.get(process.env.REACT_APP_CHECK_IF_POLICY_NUMBER_IS_REGISTERED, config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    _sendLog(['Customer Portal React - User Service - _checkIfPolicyNumberIsRegistered', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _preparePayment = (policyNumber, transactionNumber, inceptionDate, premium, country) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let user = _getUser(getState());

      let config = {
        params: {
          userId: user?.userId ? user.userId : '',
          policyNumber: policyNumber,
          transactionNumber: transactionNumber,
          inceptionDate: moment(inceptionDate).valueOf(),
          country: country,
          amount: premium
        },
        headers: authCpHeader(getState()),
        timeout: 60000
      }

      console.log(config);

      axios.get(process.env.REACT_APP_PREPARE_PAYMENT, config)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        })
    })
  }
}

export const _prepareRenewal = (policyNumber, transactionNumber, inceptionDate, premium, country) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let user = _getUser(getState());

      let config = {
        params: {
          userId: user?.userId ? user.userId : '',
          policyNumber: policyNumber,
          transactionNumber: transactionNumber,
          inceptionDate: moment(inceptionDate).valueOf(),
          country: country,
          amount: premium
        },
        headers: authCpHeader(getState()),
        timeout: 60000
      }

      console.log(config);

      axios.get(process.env.REACT_APP_PREPARE_RENEWAL, config)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        })
    })
  }
}

export const _manageRenewal = (policyNumber, country) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let user = _getUser(getState());

            let config = {
                params: {
                    userId: user?.userId ? user.userId : '',
                    policyNumber: policyNumber,
                    country: country
                },
                headers: authCpHeader(getState()),
                timeout: 60000
            }

            axios.get(process.env.REACT_APP_MANAGE_RENEWAL, config)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }
}

export const _initAutoRenewalTransactionSignature= (policyNumber, country, cardholderFullname, operation) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let user = _getUser(getState());

            let autoRenewalDetails = {
                userId: user?.userId ? user.userId : '',
                policyNumber: policyNumber,
                country: country,
                cardholderFullname: cardholderFullname,
                emailAddress: user.email,
                operation: operation
            }

            let config = {
                headers: authCpHeader(getState()),
                timeout: 60000
            }

            axios.post(process.env.REACT_APP_AUTO_GET_SIGNATURE, autoRenewalDetails, config)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }
}

export const _updateAutoRenewalCreditCardDetails = (autoRenewalDetails) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let user = _getUser(getState());
            autoRenewalDetails['userId'] = (user?.userId ? user.userId : '');
            autoRenewalDetails['country'] = (user?.country ? user.country : '');
            autoRenewalDetails['emailAddress'] = (user?.email ? user.email : '');
            axios.post(process.env.REACT_APP_AUTO_RENEWAL_UPDATE_CREDIT_CARD_DETAILS, autoRenewalDetails, {
                headers: authCpHeader(getState()),
                timeout: 60000
            })
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }
}

export const _activateAutoRenewal = (autoRenewalDetails)  => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let user = _getUser(getState());
            autoRenewalDetails['userId'] = (user?.userId ? user.userId : '');
            autoRenewalDetails['country'] = (user?.country ? user.country : '');
            let config = {
                headers: authCpHeader(getState()),
                timeout: 60000
            }
            axios.post(process.env.REACT_APP_AUTO_RENEWAL_ACTIVATE, autoRenewalDetails, config)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }
}

export const _cancelAutoRenewal = (autoRenewalDetails) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let user = _getUser(getState());
            autoRenewalDetails['userId'] = (user?.userId ? user.userId : '');
            autoRenewalDetails['country'] = (user?.country ? user.country : '');
            autoRenewalDetails['emailAddress'] = (user?.email ? user.email : '');
            let config = {
                headers: authCpHeader(getState()),
                timeout: 60000
            }

            axios.post(process.env.REACT_APP_AUTO_RENEWAL_CANCEL, autoRenewalDetails, config)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }
}

export const _getPortfolioPolicy = (policyNumber) => {
  return (dispatch, getState) => {
    let user = _getUser(getState());
    if (user.policyPortfolio && user.policyPortfolio.length > 0) {
      return user.policyPortfolio.find((item) => {
        return item.policyNumber === policyNumber;
      })
    }
    return '';
  }
}

export const _sendOTPToEmailOrMobile = (email, countryCode, phoneNumber, mobileModified) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let requestBody = {
                email: email,
                countryCode: countryCode,
                phoneNumber: phoneNumber,
                country: _getCountry(getState())
            }

            if(mobileModified) {
                requestBody["mobileModified"] = mobileModified;
            }

            let config = {
                headers: authCpHeader(getState()),
                timeout: 30000
            }

            axios.post(process.env.REACT_APP_SEND_OTP_TO_EMAIL_OR_MOBILE, requestBody, config)
                .then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                _sendLog(['Customer Portal React - User Service - _sendOTPToEmailOrMobile', JSON.stringify(error)],
                    'error');
                reject(error);
            });

        })
    }
}

export const _sendResetPasswordLink = (email) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let config = {
                params: {
                    email: email,
                    country: _getCountry(getState())
                },
                headers: authCpHeader(getState()),
                timeout: 30000
            }

            axios.get(process.env.REACT_APP_SEND_FORGET_PASSWORD_LINK, config)
                .then((response) => {
                    if (response.data === 'SUCCESS') {
                        resolve();
                    } else if (response.data === 'FAILED') {
                        reject();
                    }
                }).catch((error) => {
                    _sendLog(['Customer Portal React - User Service - _sendResetPasswordLink', JSON.stringify(error)],
                        'error');
                    reject(error);
                }
            );
        })
    }
}

export const _resetPassword = (email, password) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let config = {
                headers: authCpHeader(getState()),
                timeout: 30000
            }

            let requestBody = {
                password: password
            }

            axios.post(process.env.REACT_APP_RESET_PASSWORD, requestBody, config)
                .then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    _sendLog(['Customer Portal React - User Service - _resetPassword', JSON.stringify(error)],
                        'error');
                    reject(error);
                }
            );
        })
    }
}

export const _changePassword = (password, currentPassword) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let config = {
                headers: authCpHeader(getState()),
                timeout: 30000
            }

            let requestBody = {
                password: password,
                currentPassword: currentPassword
            }

            axios.post(process.env.REACT_APP_CHANGE_PASSWORD, requestBody, config)
                .then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    _sendLog(['Customer Portal React - User Service - _changePassword', JSON.stringify(error)],
                        'error');
                    reject(error);
                }
            );
        })
    }
}

export const _decryptResetPasswordToken = (token, iv) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                timeout: 30000
            }

            axios.get(process.env.REACT_APP_DECRYPT_RESET_PASSWORD_TOKEN, config)
                .then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    reject(error);
                });
        })
    }
}



export const _getUser = property ( 'user' );
