import React, {useState, useEffect, useRef} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom"
import '../../assets/styles/payment.css';
import QbeLogo from '../../assets/QBE-logo-on-light.png';
import Visa from '../../assets/Visa_logo.svg';
import Mastercard from '../../assets/Mastercard_logo.svg';
import styled from 'styled-components';
import QnectButton from "../../components/qnect-ui/qnect-button";
import QnectTextField from "../../components/qnect-ui/qnect-text-field";
import useConfig from "../qnect-ui/qnect-config";
import {
    _getCountry,
    _getLang, _getPolicyToken,
    _getPortfolioToken,
    _hideSpinner,
    _setLang,
    _showError,
    _showSpinner
} from "../../store/system/service";
import {
    _getPolicyPayment,
    _issuePolicyWithCreditTerms,
    _storePolicyPayment
} from "../../store/policy-payment/service";
import QnectError from "../qnect-ui/qnect-erorr";
import {makeStyles} from "@material-ui/core/styles";
import QnectCheckBox from "../qnect-ui/qnect-check-box";
import {_formatPaymentAmount, _getPolPaymentCurrency, _validateForm, getProductName, _sendLog} from "../../utils/utility";
import Header from "../custom/header";
import {ReactComponent as Back} from "../../assets/login/back_sharp.svg";
import * as TagManager from "react-gtm-module";

const InputGroupStyle = styled.div`
    width: 100%;
    margin: 0 auto;
    padding-top: 16px;
    padding-bottom: 22px;
`;

const BackWrapper = styled.div`
  width: 87px;
  padding: 2px 0;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 28px;
  color: #1d4fbb;
  font-family: Inter;
  font-weight: 500;
  cursor: pointer;
`;

const BackIcon = styled(Back)`
  margin-right: 8px;
`;

const TopWrapper = styled.div`
    position: relative;
    height: 100%;
    margin: 0 auto 0;
    width: 100%;
    max-width: 1110px;
`;

const useStyles = makeStyles(theme => ({
    [theme.breakpoints.up("md")]: {
        cvv_input: props => ({
            "& .BalloonTip": props => ({
                left: props.lang === "en" ? "42px" : "58px"
            })
        })
    }
}));

const PayLaterDetail = (props) => {
    const formId = 'renewalDetail';
    const cfg = useConfig(props.lang);
    const [lang, setLang] = useState(props.lang);
    const [productName, setProductName] = useState (null);
    const [policyNumber] = useState (props.policyPayment.policyNumber);
    const [totalAmount] = useState(props.policyPayment.amount);
    const [errorMessage] = useState(props.policyPayment.errorMessage);
    const [emailAddress, setEmailAddress] = useState (props.policyPayment.email);
    const [isCheckedOne, setIsCheckedOne] = useState(false);

    useEffect(() => {
        setProductName(getProductName(cfg, props.policyPayment.country, props.policyPayment.catalogCode));
    })

    const originalConsoleError = console.error;
    console.error = function () {
        const errorArguments = Array.from(arguments);
        const errorMessage = errorArguments.map(arg => (typeof arg === 'object' ? JSON.stringify(arg) : arg)).join(" ");
        _sendLog("pay-later-detail.js: " + errorMessage, "error")
        originalConsoleError.apply(console, arguments);
    }

    useEffect(() => {

        if (errorMessage === 'The policy has already been paid. Payment is not required.') {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'vpageview',
                    pageStep: 'Paid - Renewal - Pay Later Detail - Payment - ' + productName,
                    vPath: '/renew/pay-later-detail',
                    vProduct: productName,
                    vPolicyNo: policyNumber,
                    vPaymentCurrency: _getPolPaymentCurrency(props.policyPayment.country),
                    vPaymentAmount: totalAmount,
                    vCountry: props.policyPayment.country
                }
            });
        } else {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'vpageview',
                    pageStep: 'Renewal - Pay Later Detail - Payment - ' + productName,
                    vPath: '/renew/pay-later-detail',
                    vProduct: productName,
                    vPolicyNo: policyNumber,
                    vPaymentCurrency: _getPolPaymentCurrency(props.policyPayment.country),
                    vPaymentAmount: totalAmount,
                    vCountry: props.policyPayment.country,
                    ecommerce: {
                        checkout: {
                            actionField: {
                                step: 1
                            }
                        }
                    }
                }
            });
        }
    }, []);

    const classes = useStyles(props);

    const switchLanguage = () => {
        let newLang = lang === 'en' ? 'zh' : 'en';
        setLang(newLang);
        props.setLang(newLang);
        console.log('setting language as '+newLang);
    }

    const process = () => {
        if (_validateForm(formId)) {
            props.showSpinner();
            props.policyPayment.isCustomerPortal = true;
            props.issuePolicyWithCreditTerms(props.policyPayment).then(resp => {
                props.hideSpinner();
                props.storePolicyPayment(resp);
                props.history.push('/renew/pay-later-finish');
            }, (error) => {
                props.hideSpinner();
                props.showError([error]);
            });
        }
    }

    const handleBack = () => {
        if (!props.noLogin) {
            if (props.policyToken) {
                props.history.push('/policy');
            }
            else {
                props.history.push('/portfolio');
            }
        }
    }

    const canGoBack = () => {
        if (props.portfolioToken || props.policyToken) {
            return true;
        }
        return false;
    }

    const getQbelink = () => {
        if (props.policyPayment.country === 'MYS') {
            return "https://www.qbe.com/my/about-qbe";
        } else if (props.policyPayment.country === 'SGP') {
            return "https://www.qbe.com/sg/about-qbe";
        } else if (props.policyPayment.country === 'HKG'){
            return "https://www.qbe.com/hk/en/about-qbe";
        } else {
            return "https://www.qbe.com/about-qbe";
        }
    }

    return (
    <div className="pageContainer pay-later-detail-page">
        <div className="container">
            {canGoBack() ?
              <Header/>
              :
              <div className="topRow">
                  <div className="left">
                      <a href={getQbelink()}>
                          <img src={QbeLogo} width="122" height="35" alt="QBE"/>
                      </a>
                  </div>
                  <div className="right">
                      <div className="languageSelect" onClick={() => switchLanguage()}
                           style={{ visibility: (props.policyPayment.country === 'HKG' ||
                               props.policyPayment.country === 'HGI'  ? 'visible' : 'hidden')}}>
                          {(lang === 'en') ?
                            cfg("labels.lang_zh")
                            :
                            cfg("labels.lang_en")
                          }
                      </div>

                  </div>
              </div>
            }
            <TopWrapper>
                {canGoBack() &&
                <BackWrapper onClick={() => handleBack()}>
                    <BackIcon/>
                    Back
                </BackWrapper>
                }
            </TopWrapper>

            <div className="main-page-title">
                <span> {cfg("labels.renewal") + ' ' + productName}</span>
            </div>

            <div className="main-content-container-boxed">
                <div className="main-content-container-box">
                    <div className="main-content-title">{ cfg("labels.payment") }</div>

                    <div className="main-content-box smaller-padding">
                        <p>{ cfg("payLater.content" + props.policyPayment.country) }</p>
                        <p className="list-title">{ cfg("payLater.remarks") }</p>
                        <ol className="with-list-title">
                            <li>{ cfg("payLater.remarkItem1" + props.policyPayment.country) }</li>
                            <li>{ cfg("payLater.remarkItem2" + props.policyPayment.country) }</li>
                            <li>{ cfg("payLater.remarkItem3" + props.policyPayment.country) }</li>
                        </ol>
                    </div>

                    <div className="main-content-box">

                        <div className="highlight highlight-text">
                            {cfg("labels.policyNumber") + ': ' + policyNumber}
                        </div>

                        <div className="pricing-row">
                            <div className="pricing-block">
                                <div className="highlight-text alignCenter">{ cfg("labels.renewalPremium") }</div>
                                <div className="main-highlight-text alignCenter">{ _formatPaymentAmount(props.policyPayment.country, totalAmount) }</div>
                                {props.policyPayment.country !== 'MYS' &&
                                    <div className="credit-cards">
                                        <button className="c-card">
                                            <img src={Visa} width="80" height="46" alt="Visa"/>
                                        </button>
                                        <button className="c-card">
                                            <img src={Mastercard} width="80" height="46" alt="MasterCard"/>
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="form-container">
                            <div className="form-field-container">
                                <InputGroupStyle>
                                    <QnectTextField formId={formId} field="emailAddress" value={emailAddress}
                                                    onChange={(event) => setEmailAddress(event.target.value)}/>
                                </InputGroupStyle>
                                <div className="form-caption">
                                    <span>{ cfg('labels.confirmWording') }</span>
                                </div>
                            </div>
                            <div className="form-field-checkbox">
                                <div className="content-sub-title">
                                    { cfg("payLater.important") }
                                </div>
                                {/*<div className="">*/}
                                {/*    <input type="checkbox" id="renewConfirm" checked={isCheckedOne} onChange={(value) => setIsCheckedOne(!isCheckedOne)}/>*/}
                                {/*    <label htmlFor="renewConfirm">{ cfg("payLater.confirm") }</label>*/}
                                {/*</div>*/}

                                <div>
                                    <QnectCheckBox field="payLaterConfirm" checked={isCheckedOne} onChange={(value) => setIsCheckedOne(value)}>
                                    </QnectCheckBox>
                                </div>
                            </div>
                            <div className="form-field-container button-container button-size1">
                                <InputGroupStyle>
                                    <QnectButton field="renewNow" onClick={process} disabled={!(emailAddress && isCheckedOne && !errorMessage)}>
                                    </QnectButton>
                                </InputGroupStyle>
                            </div>
                            {
                                errorMessage &&
                                <div className="form-field-container button-container button-size1">
                                    <InputGroupStyle>
                                        <QnectError errorTitle={'Error'} errorMessage={errorMessage}></QnectError>
                                    </InputGroupStyle>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    )
}

const mapStateToProps = (state) => {
    return {
        policyPayment: _getPolicyPayment(state),
        lang: _getLang(state),
        portfolioToken: _getPortfolioToken(state),
        policyToken: _getPolicyToken(state),
        country: _getCountry(state)
    }
};

const mapDispatchToProps = {
    setLang: _setLang,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showError: _showError,
    storePolicyPayment: _storePolicyPayment,
    issuePolicyWithCreditTerms: _issuePolicyWithCreditTerms,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PayLaterDetail));

