export const SHOW_ERROR = 'SHOW_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SET_LANG = 'SET_LANG';
export const SET_OTP_TOKEN = 'SET_OTP_TOKEN';
export const SET_CP_TOKEN = 'SET_CP_TOKEN';
export const SET_NO_LOGIN = 'SET_NO_LOGIN';
export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_SECURITY_TOKEN = 'SET_SECURITY_TOKEN';
export const SET_POLICY_TOKEN = 'SET_POLICY_TOKEN';
export const SET_DOCUMENT_TOKEN = 'SET_DOCUMENT_TOKEN';
export const SET_SHOW_OTP_MODAL = 'SET_SHOW_OTP_MODAL';
export const SET_PORTFOLIO_TOKEN = 'SET_PORTFOLIO_TOKEN';
export const SET_FIND_MY_POLICY_BUSINESS_STATE = 'SET_FIND_MY_POLICY_BUSINESS_STATE';
export const RESET_STATE = 'SYSTEM_RESET_STATE';
export const RESET_POLICY_TOKEN = 'RESET_POLICY_TOKEN';
export const RESET_PORTFOLIO_TOKEN = 'RESET_PORTFOLIO_TOKEN';
export const SET_PAGE_BROKEN = 'SET_PAGE_BROKEN';
export const SET_SKIP_FLASH_DONE_TO_TRUE = 'SET_SKIP_FLASH_DONE_TO_TRUE';
export const SET_ADMIN_LOGIN = 'SET_ADMIN_LOGIN';
export const SET_SYSTEM_AVAILABLE = "SET_SYSTEM_AVAILABLE";

export const SET_GOTO_PAGE = 'SET_GOTO_PAGE';
