import * as type from './types';
import { updateObject, _sendLog } from "../../utils/utility";

const INITIAL_STATE = {
    lang: '',
    spinner: false,
    showModal: false,
    modal: {},
    showError: false,
    errors: [],
    iv: '',
    otpToken: '',
    noLogin: false,
    country: '',
    cpToken: 'CP',
    securityToken: '',
    policyToken: '',
    documentToken: '',
    showOtpModal: false,
    portfolioToken: '',
    findMyPolicyBusinessState: false,
    showPageBroken: false,
    skipFlashDone: false,
    adminLogin: '',
    systemAvailable: true,
    gotoPage: '',
    gotoPn: ''
};

const setLang = (state, action) => {
    return updateObject(
        state,
        { lang: action.lang }
    )
}

const showModalError = (state, action) => {
    _sendLog(['System Reducer - showModalError called', ...action.errors]);
    return updateObject(
        state,
        {
            showError: true,
            errors: action.errors
        }
    )
}

const clearModalError = (state, action) => {
    return updateObject(
        state,
        {
            showError: false
        }
    )
}

const showModal = (state, action) => {
    return updateObject(
        state,
        {
            showModal: true,
            modal: action.data
        }
    )
}

const hideModal = (state, action) => {
    return updateObject(
        state,
        {
            showModal: false
        }
    )
}

const showSpinner = (state, action) => {
    return updateObject(
        state,
        {
            spinner: true
        }
    )
}

const hideSpinner = (state, action) => {
    return updateObject(
        state,
        {
            spinner: false
        }
    )
}

const storeOtpToken = (state, action) => {
    let otpToken = action.data;
    return updateObject(state, {
        iv: otpToken.iv,
        otpToken: otpToken.token
    });
}

const storeCpToken = (state, action) => {
    return updateObject(
        state,
        { cpToken: action.token, iv: action.iv }
    )
}

const resetCpToken = (state, action) => {
    return updateObject(
        state,
        { cpToken: '' }
    )
}

const setNoLogin = (state, action) => {
    return updateObject(
        state,
        { noLogin: action.noLogin }
    )
}

const setCountry = (state, action) => {
    return updateObject(
        state,
        { country: action.country }
    )
}

const setSecurityToken = (state, action) => {
    return updateObject(
        state,
        { securityToken: action.token }
    )
}

const storePolicyToken = (state, action) => {
    return updateObject(
        state,
        { policyToken: action.token }
    )
}

const resetPolicyToken = (state, action) => {
    return updateObject(
      state,
      { policyToken: '' }
    )
}

const resetPortfolioToken = (state, action) => {
    return updateObject(
      state,
      { portfolioToken: '' }
    )
}

const setDocumentToken = (state, action) => {
    return updateObject(
        state,
        { documentToken: action.token }
    )
}

const setShowOtpModal = (state, action) => {
    return updateObject(state,
        { showOtpModal: action.showOtpModal}
        );
}

const storePortfolioToken = (state, action) => {
    return updateObject(state, {
        portfolioToken: action.data
    });
}

const resetState = (state) => {
    let obj = {...INITIAL_STATE};
    obj.country = state.country;
    obj.lang = state.lang;
    return obj;
}

const setFindMyPolicyBusinessState = (state, action) => {
    return updateObject(state, {
        findMyPolicyBusinessState: action.findMyPolicyBusinessState
    });
}

const setShowPageBroken = (state, action) => {
    return updateObject(state, {
        showPageBroken: action.showPageBroken
    });
}

const setSkipFlashDoneToTrue = (state) => {
    return updateObject(state, {
        skipFlashDone: true
    });
}

const setAdminLogin = (state, action) => {
    return updateObject(state,
        { adminLogin: action.adminLogin}
    );
}

const setSystemAvailable = (state, action) => {
    return updateObject(state,
        { systemAvailable: action.systemAvailable}
    );
}

const setGotoPage = (state, action) => {
    return updateObject(state,
        {
            gotoPage: action.gotoPage,
            gotoPn: action.gotoPn
        }
    );
}

export function systemReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case type.SET_LANG:
            return setLang(state, action);
        case type.SHOW_ERROR:
            return showModalError(state, action);
        case type.CLEAR_ERROR:
            return clearModalError(state, action);
        case type.SHOW_MODAL:
            return showModal(state, action);
        case type.HIDE_MODAL:
            return hideModal(state, action);
        case type.SHOW_SPINNER:
            return showSpinner(state, action);
        case type.HIDE_SPINNER:
            return hideSpinner(state, action);
        case type.SET_OTP_TOKEN:
            return storeOtpToken(state, action);
        case type.SET_CP_TOKEN:
            return storeCpToken(state, action);
        case type.SET_NO_LOGIN:
            return setNoLogin(state, action);
        case type.SET_COUNTRY:
            return setCountry(state, action);
        case type.SET_SECURITY_TOKEN:
            return setSecurityToken(state, action);
        case type.SET_POLICY_TOKEN:
            return storePolicyToken(state, action);
        case type.RESET_POLICY_TOKEN:
            return resetPolicyToken(state, action);
        case type.RESET_PORTFOLIO_TOKEN:
            return resetPortfolioToken(state, action);
        case type.SET_DOCUMENT_TOKEN:
            return setDocumentToken(state, action);
        case type.SET_SHOW_OTP_MODAL:
            return setShowOtpModal(state, action);
        case type.SET_PORTFOLIO_TOKEN:
            return storePortfolioToken(state, action);
        case type.SET_FIND_MY_POLICY_BUSINESS_STATE:
            return setFindMyPolicyBusinessState(state, action);
        case type.RESET_STATE:
            return resetState(state);
        case type.SET_PAGE_BROKEN:
            return setShowPageBroken(state, action);
        case type.SET_SKIP_FLASH_DONE_TO_TRUE:
            return setSkipFlashDoneToTrue(state);
        case type.SET_ADMIN_LOGIN:
            return setAdminLogin(state, action);
        case type.SET_SYSTEM_AVAILABLE:
            return setSystemAvailable(state, action);
        case type.SET_GOTO_PAGE:
            return setGotoPage(state, action);
        default:
            return state;
    }
}
